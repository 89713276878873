@font-face {
  font-family: myFirstFont;
  src: url("../fonts/NunitoSans-Regular.ttf");
}

body {
  font-family: myFirstFont;
}

.main-box {
  width: 400px;
  border-radius: 26px;
  box-shadow: 0px 0px 49px rgba(0, 0, 0, 0.1);
}

.header {
  background: linear-gradient(90deg, #56ccf2 0%, #2f80ed 100%);
  border-radius: 25px 25px 0px 0px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
}

.header-text {
  color: #ffffff;
  margin: 0;
  font-family: myFirstFont;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  display: inline;
}

.header-avatar {
  height: 40px;
  width: 40px;
  margin-right: 10px;
}

.header-avatar-hand {
  height: 25px;
  width: 25px;
  margin-left: 5px;
}

.header-avatar-ellipsis {
  height: 20px;
  width: auto;
  margin-left: auto;
}

.header-avatar-arrow {
  height: 8px;
  width: auto;
  margin-left: 25px;
}

.header-online {
  height: 80px;
  background-image: url("../images/header-bg.png");
  background-repeat: no-repeat;
}

.header-online-dot {
  background: linear-gradient(141.39deg, #63ff72 -14.09%, #14e097 72.41%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  margin-right: 5px;
  font-size: 30px;
}

.header-online-text {
  display: flex;
  align-items: center;
  color: #ffffff;
  border-radius: 20px;
  margin: 0;
  padding: 8px 20px 15px 20px;
  font-family: myFirstFont;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
}

.message-container {
  overflow-y: scroll;
  margin-top: -12px;
  max-height: 400px;
  padding: 0px 15px 15px 15px;
}
.message-divider {
  border: 0.8px solid #c4c4c4;
}

.message-text {
  max-width: 60%;
  color: #000000;
  background-color: #f1f1f1;
  box-shadow: 0px 0px 6px rgba(160, 160, 160, 0.16);
  border-radius: 20px;
  margin: 0;
  padding: 12px 20px;
  font-family: myFirstFont;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}
.message-left {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 10px;
}
.pointer {
  cursor: pointer;
}

.message-right {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}
.message-heading{
  width: 90%;
  color: #ffffff;
  border: none;
  background: linear-gradient(90deg, #56ccf2 0%, #2f80ed 100%);
  box-shadow: 0px 0px 6px rgba(160, 160, 160, 0.16);
  border-radius: 20px;
  margin: 0;
  padding: 12px 20px;
  font-family: myFirstFont;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}
.btn {
  max-width: 60%;
  color: #ffffff;
  border: none;
  background: linear-gradient(90deg, #56ccf2 0%, #2f80ed 100%);
  box-shadow: 0px 0px 6px rgba(160, 160, 160, 0.16);
  border-radius: 20px;
  margin: 0;
  padding: 12px 20px;
  font-family: myFirstFont;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}
.btn:hover{
  background: #157bfd;
  /* color: #000000; */
}
.btn-center {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.message-text-time {
  color: gray;
  margin: 0;
  padding: 0px 5px;
  font-family: myFirstFont;
  font-style: normal;
  font-weight: 400;
  font-size: 9px;
  display: flex;
  margin-bottom: 3px;
}

.flex-left {
  justify-content: flex-start;
}

.flex-right {
  justify-content: flex-end;
}

.main-message {
  display: flex;
  height: 60px;
}

.message-input-container {
  width: 90%;
  display: flex;
  align-items: center;
}

.message-send-container {
  width: 10%;
  display: flex;
  align-items: center;
}

*:focus {
  outline: none;
}

.message-input {
  width: 95%;
  padding: 5px 20px;
  border: none;
  font-size: 16px;
}

.message-send-icon {
  height: 70px;
  margin-right: -50px;
}

.footer {
  border-radius: 0px 0px 25px 25px;
  padding: 15px 20px;
  display: flex;
  align-items: center;
}

.footer-text {
  color: #999999;
  margin: 0;
  font-family: myFirstFont;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  display: inline;
  margin-left: auto;
}

.footer-avatar-px {
  height: 25px;
  width: auto;
  margin-left: 8px;
}

.footer-avatar-patient {
  height: 25px;
  width: auto;
  margin-left: 8px;
}

.avatar-chat-container {
  height: 25px;
  width: auto;
}

.avatar-chat {
  height: 100px;
  /* width: 150px; */
  width: auto;
}
